import React from 'react';
import Layout from 'components/Layout';
import { Container as Grid, media, Col, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useIntl } from 'gatsby-plugin-intl';

const Body = styled.section`
  position: relative;
  z-index: 1;
  padding: 80px 0;
`;

const TextBold = styled.div`
  font-weight: 500;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 24px;

  ${media.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

const Headline = styled.h3`
  ${media.xs`
    font-size: 36px;
    overflow-wrap: anywhere;
  `}
`;

export default () => {
  const translate = (id) => {
    return useIntl().formatMessage({ id });
  };

  return (
    <Layout
      seo={{
        title: 'meta_title',
        description: 'meta_description',
      }}
      tabTitlePrefix={translate('pages.disclaimer.title')}
      headerInverted
    >
      <Grid>
        <Body>
          <Header>
            <Headline>{translate('pages.disclaimer.title')}</Headline>
          </Header>
          <Grid>
            <Row>
              <Col md={2} xs={12}>
                <TextBold>{translate('pages.disclaimer.company')}</TextBold>
              </Col>
              <Col md={8} xs={12}>
                <div>Riskeeper AG</div>
                <div>Baarerstrasse 10</div>
                <div>CH-6302 Zug</div>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col md={2} xs={12}>
                <TextBold>{translate('pages.disclaimer.contact')}</TextBold>
              </Col>
              <Col md={8} xs={12}>
                <div>Telefon: +41 41 72 93 947</div>
                <div>Fax: +41 41 72 93 943</div>
                <div>
                  e-Mail:{' '}
                  <a href="mailto:contact@riskeeper.com">
                    contact@riskeeper.com
                  </a>
                </div>
                <div>
                  Website: <a href="www.riskeeper.com ">www.riskeeper.com</a>
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: '16px' }}>
              <Col md={2} xs={12}>
                <TextBold>Riskeeper AG:</TextBold>
              </Col>
              <Col md={8} xs={12}>
                <div>{translate('pages.disclaimer.chair.first')}</div>
                <div>{translate('pages.disclaimer.chair.second')}</div>
                <div>{translate('pages.disclaimer.chair.third')}</div>
              </Col>
            </Row>
            <div>
              <h5 style={{ marginTop: '40px' }}>
                {translate('pages.disclaimer.liability.contents.title')}
              </h5>
              <div>{translate('pages.disclaimer.liability.contents.text')}</div>
            </div>
            <div>
              <h5 style={{ marginTop: '40px' }}>
                {translate('pages.disclaimer.liability.links.title')}
              </h5>
              <div>{translate('pages.disclaimer.liability.links.text')}</div>
            </div>
            <div>
              <h5 style={{ marginTop: '40px' }}>
                {translate('pages.disclaimer.liability.copyright.title')}
              </h5>
              <div>
                {translate('pages.disclaimer.liability.copyright.text')}
              </div>
            </div>
          </Grid>
        </Body>
      </Grid>
    </Layout>
  );
};
